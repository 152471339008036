<template>
  <div class="user-set ly-wrap">
    <div class="currency-title"><span class="back" @click="goBack"></span>系统设置</div>
    <div class="ly-center">
      <div class="list-box">
        <div class="list-item">
          <div class="lf">接收报警推送</div>
          <div class="rt">
            <mt-switch v-model="isGet" class="switch-btn" @change="handleChange"></mt-switch>
          </div>
        </div>
        <div class="list-item" @click="goWay('/update')">
          <div class="lf">检查更新</div>
          <div class="rt">
            <div class="more-icon"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import {getPushState, putPushState} from '../../api/api.js'
import {MessageBox, Toast} from 'mint-ui';
import {isMobile} from "../../utils/util";

export default {
  name: 'UserSet',
  components: {},
  computed: {},
  data() {
    return {
      isGet: true,
    }
  },
  mounted() {
  },
  created() {
    this.loadBase()
  },
  methods: {
    loadBase() {
      if (isMobile()) {
        // eslint-disable-next-line
        plus.push.getClientInfoAsync(
            info => {
              console.log(info.clientid);
              const param = {
                cid: info.clientid
              };
              getPushState(param).then(res => {
                if (res.data.result.state == '0') {
                  this.isGet = true
                } else {
                  this.isGet = false
                }
              });
            },
            err => {
              Toast('获取用户CID失败')
            }
        );
      }
    },
    handleChange() {
      if (this.isGet) {
        this.postData(0)
      } else {
        MessageBox.confirm('确定关闭消息推送?').then(action => {
          this.postData(1)
        }).catch((err) => {
          console.log(err)
        });
      }
    },
    postData(state) {
      if (isMobile()) {
        // eslint-disable-next-line
        plus.push.getClientInfoAsync(
            info => {
              console.log(info.clientid);
              const param = {
                cid: info.clientid,
                state: state
              };
              putPushState(param).then(res => {
                Toast('操作成功')
              });
            },
            err => {
              Toast('获取用户CID失败')
            }
        );
      }
    },
    goBack(state) {

      this.$router.go(-1)
    },
    goWay(path) {
      this.$router.push({path: path})
    },
  },
  destroyed: function () {
  },
}
</script>

<style scoped lang="less">

.user-set {
  width: 100%;
  height: auto;
  background: #FFFFFF;

  .currency-title {
    border-bottom: 1px solid #F2F2F2;
  }

  .list-box {
    .list-item {
      font-size: 0.32rem;
      font-weight: bold;
      color: #333333;
      height: 0.88rem;
      line-height: 0.88rem;
      overflow: hidden;
      border-bottom: 1px solid #F2F2F2;

      .switch-btn {
        position: relative;
        top: 0.17rem;
      }

      .more-icon {
        width: 0.3rem;
        height: 0.88rem;
        background: url("~@/assets/img/more-dk.png") no-repeat center;
        background-size: 0.2rem;
      }
    }
  }


}
</style>
